body {
    padding-top: $navbar-height;
    &.admin-bar {
        .navbar-fixed-top {
            top: 46px !important;
        }
        .fs-toggle {
            top: 74px !important;
        }
    }
}

@media (min-width: 783px) {
    body {
        &.admin-bar {
            .navbar-fixed-top {
                top: 32px !important;
            }
            .fs-toggle {
                top: 60px !important;
            }
        }
    }
}