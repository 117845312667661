.content-info {
    height: 32px;
    line-height: 32px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid rgba(0,0,0,.25);

    color: #666;

    .footer-bottom {
        display: inline-block;
    }

    .nav-social {
        float: right;
        padding: 0;
        margin-left: 15px;
        list-style-type: none;
        li {
            float: left;
            a {
                display: block;
                width: 32px;
                height: 32px;
                line-height: 32px;
                background-color: transparent;
                transition: color .75s, background-color .75s;
                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }

    .nav-footer {
        display: inline-block;
        list-style-type: none;
        padding: 0;
        margin: 0 0 0 15px;
        li {
            display: inline-block;
            margin-right: 15px;
            a {
                color: #666;
            }
            &.wpml-ls-item {
                a {
                    color: #999;
                }
            }
        }
    }

}

@media (min-width: $screen-sm-min) {
    .content-info {
        height: 50px;
        line-height: 50px;
        .nav-social {
            margin-top: 9px;
            li {
                margin-left: 10px;
                a {
                    border-radius: 100%;
                }
            }
        }
    }
}