.page-header {
    h1 {
        color: $brand-primary;
    }
}

.page-content {
    .multi-col {
        margin: 15px 0;
    }
    img {
        @include img-responsive();
    }
}

.katalog-box-outer {
    @include clearfix();
    margin-left: -15px;
    margin-right: -15px;
    background-color: #fcfcfc;
    border-bottom: 1px solid #eaeaea;
    img {
        @include img-responsive();
    }
    .katalog-box-details {
        background-color: #fbfbfb;
        border-bottom: 1px solid #eaeaea;
        padding: 10px 15px;
        .katalog-box-details-right {
            text-align: right;
        }
        h1 {
            margin: 5px 0;
            font-size: 28px;
            color: $brand-primary;
            span {
                display: block;
                font-family: $font-family-base;
                font-size: $font-size-base;
                color: $text-color;
            }
            small {
                display: block;
                line-height: 1;
            }
        }
        p {
            margin: 5px 0;
        }
        .hinweis {
            color: $brand-primary;
        }
    }
}

.katalog-box {
    @include clearfix();
    position: relative;
    display: table;
    img {
        @include img-responsive();
    }
    .katalog-box-row {
        margin-top: 100px;
        display: table-row;
    }
    .katalog-box-picture {
        position: relative;
        background-color: #fff;
        display: table-cell;
        vertical-align: bottom;
        width: 100%;
        cursor: -webkit-zoom-in;
        cursor: -moz-zoom-in;
        cursor: zoom-in;
        a {

        }
        img {
            max-width: 100%;
            width: 100%;
            opacity: 1;
        }
    }
    .katalog-box-inner {
        vertical-align: top;
        top: 0;
        right: 0;
        padding: 15px;
        display: table-cell;
        border-left: 1px solid #f4f4f4;
    }
    .katalog-thumbnail-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
            width: 40px;
            border: 1px solid #eaeaea;
            margin-bottom: 10px;
            cursor: pointer;
            &:last-child {
                margin-bottom: 0;
            }
            &.active,
            &:hover,
            &:focus {
                border: 1px dashed #999;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {

    .page-content {
        .multi-col {
            column-count: 2;
            column-gap: 30px;
        }
    }

    .katalog-box-outer {
        @include clearfix();
        margin: 0 0 30px 0;
        background-color: #fbfbfb;
        border: 1px solid #eaeaea;

        .katalog-box-details {
            background-color: #fff;
            border-bottom: none;
            //padding: 15px;
        }
        .katalog-box-details-footer {
            color: #777;
        }
    }

    .katalog-box {
        width: 100%;
        position: relative;
        img {
            @include img-responsive();
        }
        .katalog-box-inner {
            background-color: #fff;
            padding: 15px;
            border-left: none;
        }
        .katalog-thumbnail-list {
            padding: 0;
            margin: 0;
            list-style-type: none;
            li {
                width: 30px;
                border: 1px solid #eaeaea;
                margin-bottom: 10px;
            }
        }
    }
}

.transitioning {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
}


#fullpage {
    background: transparent url("../images/mauer-muster.jpg") center top;
    .slide {

    }
    img {
        @include img-responsive();
    }
}

.box {
    display: block;
    min-height: 140px;
    border: 10px solid #fff;
    margin-bottom: 15px;
    background-color: #e1d0de;
    &.box-large {
        //min-height: 600px;
    }
}

@media (min-width: $screen-md-min) {

    #fullpage {
        .slide2 {
            .container-content {
                min-height: 680px;
                background: transparent url("../images/logo-slider-2.png") 0 30px no-repeat;
            }
            .slide2-text {
                margin-top: 100px;
            }
        }
    }

}

.container-content {
    min-height: 680px;
    background: transparent url("../images/logo-slider-2.png") 0 0 no-repeat;
}

.container {
    position: relative;
}

.social {
    clear: both;
    list-style-type: none;
    padding: 0;
    @include clearfix();
    li {
        float: left;
        margin-bottom: 10px;
        margin-right: 10px;
        a {
            display: block;
            width: 48px;
            height: 48px;
            font-size: 52px;
            color: $text-color;
            line-height: 48px;
            opacity: 0.7;
            img {
                @include img-responsive();
            }
            &:hover {
                color: $brand-primary;
                opacity: 1;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .social {
        top: 130px;
        right: -63px;
        position: absolute;
        list-style-type: none;
        padding: 0;
        li {
            float: none;
            margin-bottom: 10px;
            margin-right: 0;
            a {
                display: block;
                width: 48px;
                height: 48px;
                font-size: 52px;
                color: $text-color;
                line-height: 48px;
                opacity: 0.7;
                img {
                    @include img-responsive();
                }
                &:hover {
                    color: $brand-primary;
                    opacity: 1;
                }
            }
        }
    }
}

.subnavi {
    background-color: $brand-primary;
    color: #fff;
    .nav {
        > li {
            > a {
                color: #fff;
                &:hover,
                &:focus {
                    color: $brand-primary;
                    background-color: #fff;
                }
            }
            &.active > a {
                color: #fff;
                background-color: lighten($brand-primary, 10%);
            }
        }
    }
}