// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}

#loginform {
    .login-username,
    .login-password {
        display: block;
        @extend .form-group;
    }
    .login-remember {
        @extend .checkbox;
    }
    input[type="text"],
    input[type="password"] {
        @extend .form-control;
    }
    .button {
        @extend .btn;
    }
    .button-primary {
        @extend .btn-primary;
    }
}

.page-template-template-downloads {
    .outer {
        background-color: #000;
    }
    .content-info {
        margin-top: 0;
    }
    &.logged-in {
        .outer {
            background-color: transparent;
        }
        .content-info {
            margin-top: 30px;
        }
    }
}