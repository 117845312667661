.block-haendlersuche {
    position: relative;
    background-color: #fff;

    .map-col {
        @include make-xs-column(12);
    }

    .search-or {
        margin-top: 10px;
    }

    &.map-phase1 {
        .map-overlay {
            #geolocation {
                margin-bottom: 0;
            }
            .panel {
                display: none;
            }
        }
    }

    .map {
        display: none;
    }

    .map-overlay-start {
        display: none;
    }

    .map-overlay {
        margin: 0;
        padding: 0;
        top: 0;
        background-color: #fff;
        z-index: 900;

        h3 {
            font-size: 18px;
            color: $text-color;
            margin: 0;
        }

        #geolocation {
            margin: 15px 0;
        }

        .panel-scroll {
            font-size: 14px;
            max-height: 450px;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .panel {
            > .panel-scroll > .list-group {
                margin-bottom: 0;

                .list-group-item {
                    padding-top: 20px;
                    padding-bottom: 18px;
                    border-width: 1px 0;
                    border-radius: 0;
                    border-color: #e9e9e9;
                    cursor: pointer;
                    &:after {
                        //@extend .glyphicon;
                        //content: "\e259";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        padding: 20px 20px 10px 20px;
                    }
                    .list-group-item-data {
                        display: none;
                    }
                    &.active {
                        background-color: #f8f8f8;
                        color: $text-color;
                        border-color: #e9e9e9;
                        .list-group-item-heading {
                            color: $brand-primary;
                            padding-right: 0;
                        }
                        .list-group-item-text {
                            color: $text-color;
                            margin-top: 10px;
                            small {
                                display: block;
                                padding-top: 15px;
                            }
                        }
                        .list-group-item-data {
                            display: block;
                        }
                        &:after {
                            display: none;
                        }
                    }
                }

                // Add border top radius for first one
                &:first-child {
                    .list-group-item:first-child {
                        border-top: 0;
                        border-top: 1px solid #e9e9e9;
                    }
                }

                // Add border bottom radius for last one
                &:last-child {
                    .list-group-item:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        .list-group-item {
            .list-group-item-heading {
                font-size: 15px;

                margin: 0;
                padding-right: 30px;
            }
        }

        .panel-map {
            margin: 15px -15px 0;
        }

    }
}

@media (min-width: $screen-sm-min) {

    .block-haendlersuche {
        .map {
            display: block;
            margin-top: 0;
            height: 500px;
        }
        .map-overlay {
            padding: 15px;
        }
    }
}


@media (min-width: $screen-md-min) {

    .block-haendlersuche {
        margin-left: 0;
        margin-right: 0;

        &.map-phase1 {
            .map-overlay {
                width: 350px;
                left: 50%;
                margin-left: -175px;
                margin-top: 275px;
                #geolocation {
                    margin-bottom: 0;
                }
                .panel {
                    display: none;
                }
            }
            .map-overlay-start {
                display: block;
            }
        }

        .map {
            display: block;
            margin-top: 0;
            height: 700px;
        }

        .map-overlay-start {
            display: none;
            position: absolute;
            top: 0;
            background-color: rgba(0,0,0,0.5);
            z-index: 900;
            width: 100%;
            height: 701px;
        }

        .map-overlay {
            margin: 15px;
            padding: 15px;
            position: absolute;
            top: 0;
            background-color: #fff;
            border: 1px solid #e9e9e9;
            z-index: 900;

            .panel-map {
                margin: 15px -15px -15px;
            }
        }
    }

}
