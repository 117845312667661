body {
    background-color: #f9f9f9;
}

.outer {
    padding-bottom: 30px;
    background-color: #fff;
}

.page-brand {
    text-align: center;
    margin-top: 30px;
    opacity: .5;
}

.thumbnail {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

.thumb-container {
    img {
        @include img-responsive();
    }
}

@media (min-width: $screen-sm-min) {
    .outer {
        background: #f9f9f9 url(../images/b2b-background.jpg) top center repeat-y;
        background-size: 100% auto;
    }
}

@media (min-width: $screen-md-min) {
    .outer {
        min-height: 500px;
    }
}